import React, { createContext, useState, useEffect } from "react";
import firebase from "firebase";
import { accountGet, accountPost } from "./Api";
import { makeid } from "./Utils";
export const AuthContext = createContext({
  userDataPresent: false,
  user: null,
  clientId: null,
  secret: null,
  accountUid: null,
  planType: null,
  subsCancelledAt: null,
  subsLastDate: null,
});

const config = {
  apiKey: "AIzaSyBs8t9Up3sKo0f-pZjmG2FmuKh8VoW_lPE",
  authDomain: "jsonbasecore.firebaseapp.com",
  // ...
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

export default function FirebaseAuthContext(props) {
  let [state, changeState] = useState({
    userDataPresent: false,
    user: null,
    clientId: null,
    secret: null,
    accountUid: null,
    planType: null,
    subsCancelledAt: null,
    subsLastDate: null,
    listener: null,
  });

  useEffect(() => {
    if (state.listener == null) {
      changeState({
        ...state,
        listener: firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            let data = {};
            try {
              // window.localStorage.setItem("authSuccess", true);
              // window.localStorage.setItem("displayName", user.displayName);
              // window.localStorage.setItem("photoURL", user.photoURL);
              data = await accountGet(user.uid);
              window.localStorage.setItem("accountUid", data.accountUid);
              window.localStorage.setItem("clientid", data.clientId);
              window.localStorage.setItem("secret", data.secret);
            } catch (err) {
              console.log("error in accountGet - " + err.message);
              await accountPost(firebase.auth().currentUser.uid);
              // console.log("posted data " + JSON.stringify(data1));
            }
            const scd = data.stripeSubscriptionCancelledDate
              ? new Date(data.stripeSubscriptionCancelledDate._seconds * 10000)
              : null;
            const sld = data.stripeSubscriptionLastDate
              ? new Date(data.stripeSubscriptionLastDate._seconds * 10000)
              : null;
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: user,
              clientId: data.clientId,
              secret: data.secret,
              accountUid: data.accountUid,
              planType: data.planType,
              subsCancelledAt: scd,
              subsLastDate: sld,
            }));
          } else {
            // console.log("onAuthStateChanged with no user");
            if (
              !(
                window.localStorage.getItem("clientid") ||
                window.localStorage.getItem("secret")
              )
            ) {
              // console.log("setting cid and sec");
              const cid = makeid(12);
              const sec = makeid(32);
              window.localStorage.setItem("clientid", cid);
              window.localStorage.setItem("secret", sec);
            }
            // window.localStorage.setItem("authSuccess", false);
            // window.localStorage.setItem("displayName", null);
            // window.localStorage.setItem("photoURL", null);
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: null,
              clientId: null,
              secret: null,
              accountUid: null,
              planType: null,
              subsCancelledAt: null,
              subsLastDate: null,
            }));
          }
        }),
      });
    }
    return () => {
      // console.log("returning state");
      if (state.listener) state.listener();
    };
  }, [state]);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
}
