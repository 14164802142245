import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormControl, FormLabel, Checkbox, propNames } from "@chakra-ui/react";
import { Resizable } from "re-resizable";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
// import 'codemirror/theme/material.css';
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/hint/show-hint.css";

import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/lint/lint.js";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/edit/closebrackets";
import "codemirror/mode/javascript/javascript";
import { JSHINT } from "jshint";

window.JSHINT = JSHINT;
// window.jsonlint = jsonlint;
// const jsonlint = require("jsonlint-mod");
// window.jsonlint = jsonlint;

const Wrapper = styled.div`
  .CodeMirror {
    background-color: #e2e8f0;
    // overflow: auto;
    min-height: 100% !important;
    width: 600px;
    height: 300px;
  }
`;
export default function CmEditor({ code, onChange, readOnly }) {
  // const [width, setWidth] = useState(600);
  // const [height, setHeight] = useState(400);
  const [codeEditorState, setCodeEditorState] = useState();
  const [lintEnabled, setLintEnabled] = useState(true);

  useEffect(() => {
    setCodeEditorState(code);
  }, [code]);

  const toggleLint = () => {
    setLintEnabled(!lintEnabled);
  };

  return (
    <>
      <Wrapper>
        <CodeMirror
          background="#666666"
          value={codeEditorState}
          // height="auto"

          autoCursor={true}
          onChange={(editor, data, value) => onChange(value)}
          options={{
            readOnly: readOnly ? "nocursor" : false,
            gutters: ["CodeMirror-lint-markers"],
            //   mode: "javascript",
            autoCloseBrackets: true,
            // mode: "application/json",
            mode: {
              name: "javascript",
              json: true,
              statementIndent: 2,
            },
            lineNumbers: true,
            lineWrapping: true,
            lint: lintEnabled,
            //   indentWithTabs: false,
            //   tabSize: 2
          }}
        />
      </Wrapper>
      <div>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="auto-validate" mb="0">
            Auto Validate
          </FormLabel>
          <Checkbox
            defaultIsChecked
            size="md"
            colorScheme="blue"
            onChange={toggleLint}
          />
        </FormControl>
      </div>
    </>
  );
}
