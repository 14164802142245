// import React, { useEffect } from "react";
import { Link } from "@chakra-ui/react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 31px 93px 0px 93px;
  hr {
    // border: 1px solid #aaaaaa;
    border: none;
    background: #aaaaaa;
    height: 1px;
    color: #aaaaaa;
  }
  .footer {
    min-height: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

export default function Footer(props) {
  //   useEffect(() => {
  //     // check if it has clientid/secret in local storage
  //   }, []);

  return (
    <Wrapper>
      <hr />
      <div className="footer">
        <Link href="/privacy">Privacy And Cookies</Link>
        <Link href="/terms">Terms And Conditions</Link>
      </div>
    </Wrapper>
  );
}
