import React, { useState, useEffect, useContext } from "react";
import { Link, Button, ButtonGroup, Avatar, Spinner } from "@chakra-ui/react";
import { LoginIcon, Logo } from "./SvgIcons";

import styled from "styled-components";
import { AuthContext } from "./FirebaseAuthContext";
import { usedBytesGet } from "./Api";

// import firebase from "firebase";
// import { useHistory } from "react-router";
const Usage = styled.div`
  margin-left: 93px;
  font-size: 12px;
  color: #666666;
`;

const Wrapper = styled.div`
  margin: 31px 93px 0px 93px;
  hr {
    // border: 1px solid #aaaaaa;
    border: none;
    background: #aaaaaa;
    height: 1px;
    color: #aaaaaa;
  }
  .header {
    min-height: 80px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .headerright {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      text-align: right;
      span {
        float: right;
        display: block;
        font-size: 12px;
        color: #666666;
      }
    }
  }
`;

export default function Header(props) {
  const [usedBytes, setUsedBytes] = useState(0.0);
  // const history = useHistory();
  const authValue = useContext(AuthContext);
  const [isSignedIn, setIsSignedIn] = useState(
    window.localStorage.getItem("accountUid")
  );
  const [displayName, setDisplayName] = useState(
    window.localStorage.getItem("displayName")
      ? window.localStorage.getItem("displayName")
      : "Unknown"
  );
  const [photoURL, setphotoURL] = useState(
    window.localStorage.getItem("photoURL")
      ? window.localStorage.getItem("photoURL")
      : "Not Applicable"
  );
  const [loadingUser, setLoadingUser] = useState(false);
  const [planType, setPlanType] = useState(null);
  // useEffect(() => {

  // }, [authValue]);

  useEffect(() => {
    // check if it has clientid/secret in local storage

    // if (window.localStorage.getItem("authSuccess") === "true") {

    if (authValue.user != null) {
      setDisplayName(authValue.user.displayName);
      setphotoURL(authValue.user.photoURL);
      setPlanType(authValue.planType);
      setIsSignedIn(true);
    } else {
      if (window.localStorage.getItem("accountUid")) {
        setLoadingUser(true);
      } else {
        setIsSignedIn(false);
        setDisplayName("Unknown");
        setphotoURL("Not Applicable");
        setLoadingUser(false);
      }
    }
    usedBytesGet().then((sizeobj) => {
      setUsedBytes(sizeobj.size);
    });
  }, [authValue]);

  // const navigateToAuth = (e) => {
  //   e.preventDefault();
  //   history.push("auth");
  // };

  return (
    <>
      <Wrapper>
        <div className="header">
          <Link href="/">
            <Logo />
          </Link>
          <div className="headerright">
            <div>
              {isSignedIn ? (
                <Link href="/account">
                  <Avatar
                    alignSelf="end"
                    colorScheme="blue"
                    bgColor="blue.900"
                    name={displayName}
                    src={photoURL}
                  />
                  <div>
                    <span>{displayName}</span>
                  </div>
                </Link>
              ) : (
                <>
                  {loadingUser ? (
                    <Spinner />
                  ) : (
                    <ButtonGroup>
                      <Link href="/pricing">
                        <Button
                          leftIcon={<LoginIcon />}
                          colorScheme="blue"
                          bgColor="blue.900"
                          color="white"
                          variant="solid"
                        >
                          Pricing
                        </Button>
                      </Link>
                      <Link href="/auth">
                        <Button
                          leftIcon={<LoginIcon />}
                          colorScheme="blue"
                          bgColor="blue.900"
                          color="white"
                          variant="solid"
                        >
                          Sign In
                        </Button>
                      </Link>
                    </ButtonGroup>
                  )}
                  <div>
                    <span>Encrypted At Rest.</span>
                  </div>
                  <div>
                    <span>Free & Unlimited (Fair Use) API Requests*</span>
                  </div>
                </>
              )}
            </div>
            <div></div>
          </div>
        </div>
        <hr />
      </Wrapper>
      <Usage>
        {(usedBytes / (1024 * 1024)).toFixed(6)} MB
        {planType ? (
          " (" + planType + " Plan"
        ) : (
          <span>
            <span>/ 100 MB Used </span>
            <Link href="/pricing">(Free plan - click here to upgrade</Link>
          </span>
        )}
        )
      </Usage>
    </>
  );
}
