import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import Auth from "./components/common/Auth";
import Header from "./components/common/Header";
import Main from "./components/common/Main";
import UserAccount from "./components/common/UserAccount";
import Footer from "./components/common/Footer";
import Privacy from "./components/common/Privacy";
import Terms from "./components/common/Terms";
import Pricing from "./components/common/Pricing";
import Payment from "./components/common/Payment";
import { ChakraProvider } from "@chakra-ui/react";
import FirebaseAuthContext from "./components/common/FirebaseAuthContext";
import { AuthContext } from "./components/common/FirebaseAuthContext";

// import { useHistory } from "react-router";

// Configure Firebase.

const Body = styled.div`
  min-height: calc(100vh - 140px);
  @media (max-width: 800px) {
    min-height: auto;
  }
`;

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const authValue = useContext(AuthContext);
  return window.localStorage.getItem("accountUid") === null ? (
    // return !authValue.userDataPresent && authValue.user == null ? (
    <Redirect
      to={{
        pathname: "/auth",
      }}
    />
  ) : (
    <Route {...rest} component={Component}></Route>
  );
};

export default function App(props) {
  return (
    <ChakraProvider>
      <FirebaseAuthContext>
        <Header />
        <Body>
          <BrowserRouter>
            <Switch>
              <Route path="/pricing" exact component={Pricing} />
              <Route path="/auth" exact component={Auth} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/" exact component={Main} />
              <PrivateRoute exact path="/account" component={UserAccount} />
              <PrivateRoute
                exact
                path="/payment/:priceId"
                component={Payment}
              />
            </Switch>
          </BrowserRouter>
        </Body>
        <Footer />
      </FirebaseAuthContext>
    </ChakraProvider>
  );
}
