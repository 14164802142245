import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Button, useToast, Badge, Link, Select, Input } from "@chakra-ui/react";
import { SaveIcon, ClipboardIcon } from "./SvgIcons";
import { docsGet, docsPut, allcollsGet } from "./Api";
import { config } from "./Config";
import { copyToClipboard } from "./Utils";
import { AuthContext } from "./FirebaseAuthContext";
import CmEditor from "./CmEditor";

const EmptyIcon = styled.span``;
const Wrapper = styled.div`
  margin: 31px 93px;
  span {
    margin: 0px 5px;
    font-size: 12px;
  }
  .collcrumb {
    display: flex;
    flex-direction: row;
    // span {
    //   font-size: 24px;
    // }
    .coll {
      display: flex;
      flex-direction: column;
      .collDivider {
        text-align: center;
        margin: 0px 5px 0px 5px;
      }
    }
  }
  .label,
  .collcrumb.coll.label {
    span {
      font-size: 12px;
      color: #666666;
    }
  }
`;

export default function Main(props) {
  const [placeholder, setPlaceholder] = useState([]);
  const [textContent, setTextContent] = useState("");
  const [currBase, setCurrBase] = useState("base1");
  const [currColl, setCurrColl] = useState("collection1");
  const [distinctBases, setDistinctBases] = useState([]);
  const [collectionNames, setCollectionNames] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [clientid, setClientid] = useState(null);
  const [secret, setSecret] = useState(null);
  const [reloadPlaceholder, setReloadPlaceholder] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const toast = useToast();
  const authValue = useContext(AuthContext);

  useEffect(() => {
    // console.log("initial or authValue changed");

    if (authValue.user != null) {
      setClientid(authValue.clientId);
      setSecret(authValue.secret);
      setLoadingUser(false);
    } else {
      if (window.localStorage.getItem("accountUid")) {
        setLoadingUser(true);
      } else {
        setSecret(window.localStorage.getItem("secret"));
        setClientid(window.localStorage.getItem("clientid"));
        setLoadingUser(false);
      }
    }
    function loadPlaceholder() {
      if (clientid && secret) {
        console.log("clientid and secret fine");
        allcollsGet(clientid).then((docs) => {
          if (docs != null) {
            console.log("allcolls come through " + docs.length);
            const allbases = docs.map((doc) => doc.base).sort();
            setDistinctBases([...new Set(allbases)]);
            setCurrBase(allbases[0]);
            const givenBaseColls = docs
              .filter((doc) => doc.base === allbases[0])
              .map((doc) => doc.collection);
            setCollectionNames(givenBaseColls);
            setCurrColl(givenBaseColls[0]);
          }
        });
      }
    }
    loadPlaceholder();
  }, [authValue, clientid, secret]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    docsGet(currBase, currColl).then((docs) => {
      if (docs != null) {
        console.log("curr docs come through " + docs.length);
        setPlaceholder(docs);
      }
      setReloadPlaceholder(false);
    });
  }, [currBase, currColl, reloadPlaceholder]);

  const onBaseChange = (e) => {
    const selectedBaseObjs = distinctBases.filter(
      (baseObj) => baseObj.base === e.target.value
    );
    setCollectionNames(selectedBaseObjs.values().sort());
    setCurrBase(e.target.value);
    setCurrColl(selectedBaseObjs.values().sort()[0]);
  };
  const onEditorBodyChange = (text) => {
    if (textContent !== text) {
      setTextContent(text);
    }
  };

  const justErased = () => {
    toast({
      position: "top",
      title: "Erased",
      description: "All your account data has been erased.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    window.localStorage.removeItem("justErased");
  };

  const onCopyText = () => {
    copyToClipboard(window.localStorage.getItem("secret"));
    toast({
      position: "top",
      title: "Copied",
      description: "Copied secret to clipboard.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };
  const onClickSaveDocs = async () => {
    setIsSaving(true);
    const res = await docsPut(currBase, currColl, textContent.jsObject);

    if (res === null) {
      setIsSaving(false);
      toast({
        position: "top",
        title: "Oops",
        description: "Unable to save the collection. Please try later.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        position: "top",
        title: "Success",
        description: "Collection has been saved.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setReloadPlaceholder(true);
      setIsSaving(false);
    }
  };

  // const colors = {
  //   default: "#1A365D",
  //   background: "#E2E8F0",
  // };
  // const style = {
  //   body: {
  //     marginTop: "20px",
  //     fontSize: "13px",
  //   },
  // };

  return (
    <>
      {window.localStorage.getItem("justErased") && justErased()}
      <Wrapper>
        <div className="collcrumb">
          <div className="coll">
            {distinctBases.length > 0 ? (
              <Select
                onChange={onBaseChange}
                icon={<EmptyIcon />}
                iconSize="1px"
                variant="unstyled"
                fontSize="24px"
                height="24px"
                paddingRight="3px"
              >
                {distinctBases.map((key) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  );
                })}
              </Select>
            ) : (
              <Input
                isRequired
                onChange={(e) => setCurrBase(e.target.value)}
                variant="unstyled"
                defaultValue={currBase}
                width="120px"
              />
            )}
            <div className="label">
              <span>Base</span>
            </div>
          </div>
          <div className="coll">
            <div className="collDivider">
              <span>/</span>
            </div>
          </div>
          <div className="coll">
            {collectionNames.length > 0 ? (
              <Select
                onChange={(e) => setCurrColl(e.target.value)}
                icon={<EmptyIcon />}
                iconSize="1px"
                variant="unstyled"
                fontSize="24px"
                height="24px"
                paddingRight="3px"
              >
                {collectionNames.map((key) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  );
                })}
              </Select>
            ) : (
              <Input
                isRequired
                onChange={(e) => setCurrColl(e.target.value)}
                variant="unstyled"
                defaultValue={currColl}
                width="120px"
              />
            )}
            <div className="label">
              <span>Collection</span>
            </div>
          </div>
        </div>
        {/* <div style={{ marginTop: "20px" }}> */}

        <CmEditor
          code={JSON.stringify(placeholder, null, 2)}
          onChange={onEditorBodyChange}
          readOnly={loadingUser}
        />

        <Button
          disabled={loadingUser}
          marginTop="25px"
          isLoading={isSaving}
          onClick={onClickSaveDocs}
          leftIcon={<SaveIcon />}
          colorScheme="blue"
          bgColor="blue.900"
          color="white"
          variant="solid"
        >
          Save
        </Button>
        <div style={{ margin: "31px 0px" }}></div>
        <div className="collcrumb">
          <div className="coll">
            <div>
              <span>API Endpoint</span>
            </div>
            <div className="label">
              <span>
                {config.jbApiUrl +
                  "/" +
                  clientid +
                  "/" +
                  currBase +
                  "/" +
                  currColl}
              </span>
              <Badge>GET</Badge>
              <Badge>PUT</Badge>
              <Badge>POST</Badge>
            </div>
          </div>
        </div>

        <div style={{ margin: "31px 0px" }}></div>
        <div className="collcrumb">
          <div className="coll">
            <div>
              <span>Headers</span>
            </div>
            <div className="label">
              <span>secret</span>

              <span style={{ marginLeft: "20px" }}>{secret}</span>
              <Link onClick={onCopyText}>
                <ClipboardIcon width="17px" height="19px" />
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
