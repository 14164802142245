// import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { termsMdText } from "./termsMarkdown.js";

const Wrapper = styled.div`
  margin: 31px 93px 0px 93px;
  font-size: 13px;
  h1 {
      font-size: 28px
  }
  h2 {
    font-size: 22px
  }
  h3 {
      font-size 18px
  }
  hr {
    // border: 1px solid #aaaaaa;
    border: none;
    background: #aaaaaa;
    height: 1px;
    color: #aaaaaa;
  }
  .footer {
    min-height: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

export default function Terms(props) {
  //   useEffect(() => {
  //     // check if it has clientid/secret in local storage
  //   }, []);

  return (
    <Wrapper>
      <ReactMarkdown children={termsMdText} />
    </Wrapper>
  );
}
