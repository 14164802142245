import { config } from "./Config";
// import { getItem } from "utils/utils";

const handleErrors = async (response) => {
  if (!response.ok) {
    if (response.status === 404 || response.status === 400) return null;
    throw await response.json();
  }
  return response;
};

const fetchOverCustom = async (url, params, headers) => {
  try {
    const fetched = await fetch(url, {
      ...params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    const noError = await handleErrors(fetched);
    const json = noError ? await noError.json() : null;
    return json;
  } catch (err) {
    throw err;
  }
};

// const fetchOver = async (url, params, header) => {
//   const fetched = await fetch(url, {
//     ...params,
//     headers: {
//       Accept:
//         "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
//       "Content-Type": "application/x-www-form-urlencoded",
//       ...header,
//     },
//   });
//   try {
//     const noError = await handleErrors(fetched);
//     const json = noError ? await noError.json() : null;
//     return json;
//   } catch (err) {
//     throw err;
//   }
// };

// const fetchWithAuth = async (url, params) => {
//   const tokenDetails = getItem("tokenDetails");
//   const guestLogin = getItem("guestLogin");
//   const authHeader = tokenDetails
//     ? { authorization: `Bearer ${tokenDetails.access_token}` }
//     : {};
//   const guestHeader = guestLogin ? { guest_email: guestLogin.email } : {};
//   const { searchparams, ...remaining } = params;
//   let urlobj = new URL(url);
//   if (searchparams) {
//     urlobj.search = new URLSearchParams(searchparams);
//   }
//   const fetched = await fetch(urlobj, {
//     ...remaining,
//     headers: {
//       Accept:
//         "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
//       "Content-Type": "application/json",
//       ...authHeader,
//       ...guestHeader,
//     },
//   });
//   try {
//     const noError = await handleErrors(fetched);
//     const json = noError ? await noError.json() : null;
//     return json;
//   } catch (err) {
//     throw err;
//   }
// };

export const docsPut = async (base, collection, payload) => {
  const data = await fetchOverCustom(
    config.jbApiUrl +
      "/" +
      window.localStorage.getItem("clientid") +
      "/" +
      base +
      "/" +
      collection,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const docsGet = async (base, collection) => {
  try {
    const data = await fetchOverCustom(
      config.jbApiUrl +
        "/" +
        window.localStorage.getItem("clientid") +
        "/" +
        base +
        "/" +
        collection,
      {
        method: "GET",
      },
      {
        secret: window.localStorage.getItem("secret"),
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const usedBytesGet = async () => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/usedbytes/" + window.localStorage.getItem("clientid"),
    {
      method: "GET",
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const accountGet = async (uid) => {
  const data = await fetchOverCustom(config.jbApiUrl + "/account/" + uid, {
    method: "GET",
  });
  return data;
};

export const allcollsGet = async (clientId) => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/allcolls/" + clientId,
    {
      method: "GET",
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const accountDelete = async (uid) => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/account/" + uid,
    {
      method: "DELETE",
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const accountPost = async (uid) => {
  const data = await fetchOverCustom(
    config.jbApiUrl +
      "/account/" +
      window.localStorage.getItem("clientid") +
      "/" +
      uid,
    {
      method: "POST",
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const accountPut = async (uid, newClientid, newSecret) => {
  const data = await fetchOverCustom(
    config.jbApiUrl +
      "/account/" +
      window.localStorage.getItem("clientid") +
      "/" +
      uid,
    {
      method: "POST",
    },
    {
      secret: window.localStorage.getItem("secret"),
      newSecret: newSecret,
      newClientid: newClientid,
    }
  );
  return data;
};

export const subsaccountPost = async (uid, payload) => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/subsaccount/" + uid,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

// FIXME this and other operations on Account should all be PUT
export const subsbeginPost = async (uid, payload) => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/subsbegin/" + uid,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const subsendPost = async (uid) => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/subsend/" + uid,
    {
      method: "POST",
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};

export const subsundocancelPost = async (uid) => {
  const data = await fetchOverCustom(
    config.jbApiUrl + "/subsundocancel/" + uid,
    {
      method: "POST",
    },
    {
      secret: window.localStorage.getItem("secret"),
    }
  );
  return data;
};
