import React from "react";
import { Icon } from "@chakra-ui/react";

export const Logo = ({
  width = "162",
  height = "70",
  fill = "#E2E8F0",
  stroke = "#1A365D",
}) => (
  <Icon
    width={width + "px"}
    height={height + "px"}
    viewBox="0 0 162 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="162" height="70" rx="4" fill={fill} />
    <path
      d="M17.0441 23.7411C16.6458 23.7411 16.3471 23.6276 16.148 23.4006C15.9488 23.183 15.8492 22.9087 15.8492 22.5776V21.6838C15.8492 21.4284 15.8729 21.2061 15.9204 21.0169C15.9773 20.8277 16.0436 20.6575 16.1195 20.5061C16.1954 20.3548 16.2807 20.2224 16.3755 20.1089C16.4704 19.9859 16.5605 19.8724 16.6458 19.7684C16.807 19.5792 16.9161 19.4184 16.973 19.286C17.0394 19.1441 17.0726 18.9975 17.0726 18.8461C17.0726 18.4867 16.9066 18.2266 16.5747 18.0658C16.2428 17.8955 15.7544 17.8104 15.1095 17.8104H14V16.9307H15.1095C15.7544 16.9307 16.2428 16.8503 16.5747 16.6895C16.9066 16.5192 17.0726 16.2544 17.0726 15.895C17.0726 15.7436 17.0394 15.6017 16.973 15.4693C16.9161 15.3274 16.807 15.1619 16.6458 14.9727C16.5605 14.8687 16.4704 14.7599 16.3755 14.6464C16.2807 14.5234 16.1954 14.391 16.1195 14.2491C16.0436 14.0978 15.9773 13.9275 15.9204 13.7383C15.8729 13.5397 15.8492 13.3127 15.8492 13.0573V12.1634C15.8492 11.8324 15.9488 11.5581 16.148 11.3405C16.3471 11.1135 16.6458 11 17.0441 11H19.5477V11.8797H16.8307V12.9722C16.8307 13.36 16.8876 13.6579 17.0014 13.866C17.1152 14.0741 17.248 14.2775 17.3997 14.4761C17.561 14.6842 17.7127 14.9018 17.8549 15.1288C17.9972 15.3463 18.0683 15.6065 18.0683 15.9091C18.0683 16.3064 17.9261 16.628 17.6416 16.8739C17.3665 17.1199 16.9872 17.2759 16.5036 17.3422V17.3989C16.9872 17.4651 17.3665 17.6212 17.6416 17.8671C17.9261 18.1131 18.0683 18.4347 18.0683 18.8319C18.0683 19.1346 17.9972 19.3995 17.8549 19.6265C17.7127 19.844 17.561 20.0569 17.3997 20.2649C17.248 20.4636 17.1152 20.6669 17.0014 20.875C16.8876 21.0831 16.8307 21.3811 16.8307 21.7689V22.8614H19.5477V23.7411H17.0441Z"
      fill={stroke}
    />
    <path
      d="M32.3674 29.4132H33.3204L32.5665 33.3434H30.7315L32.3674 29.4132ZM35.7671 29.4132H36.7202L35.9663 33.3434H34.1313L35.7671 29.4132Z"
      fill={stroke}
    />
    <path
      d="M39.5454 41.7854H43.1016V33.5562H39.4173V32.5914H44.2396V41.076C44.2396 41.5679 44.1068 41.9699 43.8413 42.282C43.5852 42.5941 43.1774 42.7502 42.6179 42.7502H39.5454V41.7854ZM43.6706 30.9739C43.3387 30.9739 43.1063 30.9077 42.9736 30.7753C42.8408 30.6334 42.7744 30.4584 42.7744 30.2503V30.0233C42.7744 29.8152 42.8408 29.645 42.9736 29.5125C43.1063 29.3707 43.3387 29.2997 43.6706 29.2997C44.0025 29.2997 44.2348 29.3707 44.3676 29.5125C44.5004 29.645 44.5667 29.8152 44.5667 30.0233V30.2503C44.5667 30.4584 44.5004 30.6334 44.3676 30.7753C44.2348 30.9077 44.0025 30.9739 43.6706 30.9739Z"
      fill={stroke}
    />
    <path
      d="M50.9766 40.0828C50.2084 40.0828 49.5446 39.9551 48.9851 39.6997C48.4351 39.4349 47.9657 39.0849 47.5768 38.6498L48.345 37.983C48.6959 38.3518 49.0799 38.6356 49.4972 38.8342C49.9145 39.0329 50.4171 39.1322 51.005 39.1322C51.574 39.1322 52.0387 39.0329 52.3991 38.8342C52.7689 38.6262 52.9538 38.3046 52.9538 37.8694C52.9538 37.6803 52.9159 37.5242 52.84 37.4012C52.7737 37.2688 52.6788 37.1648 52.5555 37.0891C52.4323 37.0134 52.2948 36.9567 52.143 36.9188C51.9913 36.8715 51.8301 36.8337 51.6594 36.8053L50.5072 36.6351C50.2511 36.5972 49.9714 36.5452 49.6679 36.479C49.3739 36.4128 49.1036 36.3087 48.8571 36.1669C48.6105 36.0155 48.4019 35.8169 48.2312 35.5709C48.07 35.325 47.9894 35.0034 47.9894 34.6061C47.9894 34.2372 48.0605 33.9204 48.2027 33.6555C48.3545 33.3812 48.5631 33.1542 48.8286 32.9745C49.0942 32.7853 49.4071 32.6482 49.7675 32.563C50.1278 32.4684 50.5214 32.4211 50.9481 32.4211C51.612 32.4211 52.1857 32.5252 52.6693 32.7333C53.1625 32.9414 53.5845 33.2299 53.9354 33.5988L53.1957 34.294C53.1103 34.1899 53.0013 34.0859 52.8685 33.9819C52.7452 33.8683 52.5887 33.769 52.3991 33.6839C52.2189 33.5893 52.0055 33.5136 51.759 33.4569C51.5124 33.4001 51.2279 33.3718 50.9055 33.3718C50.327 33.3718 49.8813 33.4711 49.5683 33.6697C49.2649 33.8683 49.1131 34.1568 49.1131 34.5352C49.1131 34.7244 49.1463 34.8852 49.2127 35.0176C49.2886 35.1406 49.3881 35.2399 49.5114 35.3156C49.6347 35.3912 49.7722 35.4527 49.9239 35.5C50.0852 35.5378 50.2464 35.5709 50.4076 35.5993L51.5598 35.7696C51.8253 35.8074 52.1051 35.8594 52.3991 35.9256C52.6931 35.9919 52.9633 36.1006 53.2099 36.252C53.4565 36.3939 53.6603 36.5878 53.8216 36.8337C53.9923 37.0796 54.0776 37.4012 54.0776 37.7985C54.0776 38.5268 53.7931 39.0896 53.2241 39.4869C52.6646 39.8842 51.9154 40.0828 50.9766 40.0828Z"
      fill={stroke}
    />
    <path
      d="M59.4206 40.0828C58.9085 40.0828 58.4438 39.993 58.0266 39.8132C57.6188 39.6335 57.2679 39.3781 56.9739 39.0471C56.6894 38.7066 56.4713 38.3046 56.3196 37.8411C56.1678 37.3681 56.092 36.8384 56.092 36.252C56.092 35.675 56.1678 35.15 56.3196 34.6771C56.4713 34.2041 56.6894 33.8021 56.9739 33.4711C57.2679 33.1306 57.6188 32.8704 58.0266 32.6907C58.4438 32.511 58.9085 32.4211 59.4206 32.4211C59.9327 32.4211 60.3926 32.511 60.8004 32.6907C61.2177 32.8704 61.5685 33.1306 61.853 33.4711C62.147 33.8021 62.3699 34.2041 62.5216 34.6771C62.6733 35.15 62.7492 35.675 62.7492 36.252C62.7492 36.8384 62.6733 37.3681 62.5216 37.8411C62.3699 38.3046 62.147 38.7066 61.853 39.0471C61.5685 39.3781 61.2177 39.6335 60.8004 39.8132C60.3926 39.993 59.9327 40.0828 59.4206 40.0828ZM59.4206 39.1038C60.056 39.1038 60.5681 38.9146 60.9569 38.5363C61.3457 38.1579 61.5401 37.5715 61.5401 36.7769V35.727C61.5401 34.9325 61.3457 34.346 60.9569 33.9677C60.5681 33.5893 60.056 33.4001 59.4206 33.4001C58.7852 33.4001 58.2731 33.5893 57.8843 33.9677C57.4955 34.346 57.3011 34.9325 57.3011 35.727V36.7769C57.3011 37.5715 57.4955 38.1579 57.8843 38.5363C58.2731 38.9146 58.7852 39.1038 59.4206 39.1038Z"
      fill={stroke}
    />
    <path
      d="M65.0765 39.9126V32.5914H66.2145V33.7832H66.2714C66.3473 33.6035 66.4421 33.4332 66.5559 33.2724C66.6697 33.1022 66.8072 32.9556 66.9684 32.8326C67.1391 32.7096 67.3383 32.6103 67.5659 32.5347C67.7935 32.459 68.059 32.4211 68.3625 32.4211C69.1306 32.4211 69.747 32.6671 70.2117 33.1589C70.6764 33.6413 70.9087 34.3271 70.9087 35.2162V39.9126H69.7707V35.4149C69.7707 34.7433 69.6237 34.2467 69.3297 33.9251C69.0358 33.594 68.6043 33.4285 68.0353 33.4285C67.8077 33.4285 67.5848 33.4569 67.3667 33.5136C67.1486 33.5704 66.9542 33.6555 66.7835 33.769C66.6128 33.8825 66.4753 34.0291 66.371 34.2089C66.2667 34.3886 66.2145 34.5967 66.2145 34.8332V39.9126H65.0765Z"
      fill={stroke}
    />
    <path
      d="M74.5305 29.4132H76.3655L74.7296 33.3434H73.7766L74.5305 29.4132ZM77.9302 29.4132H79.7652L78.1294 33.3434H77.1763L77.9302 29.4132Z"
      fill={stroke}
    />
    <path
      d="M85.0086 40.0402C84.6198 40.0402 84.3448 39.9598 84.1836 39.799C84.0319 39.6382 83.956 39.4349 83.956 39.189V38.9336C83.956 38.6876 84.0319 38.4843 84.1836 38.3235C84.3448 38.1627 84.6198 38.0823 85.0086 38.0823C85.3974 38.0823 85.6677 38.1627 85.8195 38.3235C85.9807 38.4843 86.0613 38.6876 86.0613 38.9336V39.189C86.0613 39.4349 85.9807 39.6382 85.8195 39.799C85.6677 39.9598 85.3974 40.0402 85.0086 40.0402ZM85.0086 34.4217C84.6198 34.4217 84.3448 34.3413 84.1836 34.1805C84.0319 34.0197 83.956 33.8163 83.956 33.5704V33.315C83.956 33.0691 84.0319 32.8657 84.1836 32.7049C84.3448 32.5441 84.6198 32.4637 85.0086 32.4637C85.3974 32.4637 85.6677 32.5441 85.8195 32.7049C85.9807 32.8657 86.0613 33.0691 86.0613 33.315V33.5704C86.0613 33.8163 85.9807 34.0197 85.8195 34.1805C85.6677 34.3413 85.3974 34.4217 85.0086 34.4217Z"
      fill={stroke}
    />
    <path
      d="M100.602 29.4132H101.555L100.801 33.3434H98.9663L100.602 29.4132ZM104.002 29.4132H104.955L104.201 33.3434H102.366L104.002 29.4132Z"
      fill={stroke}
    />
    <path
      d="M107.681 29.4132H108.819V33.7832H108.875C109.35 32.8752 110.104 32.4211 111.137 32.4211C112.038 32.4211 112.74 32.7569 113.242 33.4285C113.755 34.1001 114.011 35.0412 114.011 36.252C114.011 37.4627 113.755 38.4039 113.242 39.0754C112.74 39.747 112.038 40.0828 111.137 40.0828C110.104 40.0828 109.35 39.6288 108.875 38.7207H108.819V39.9126H107.681V29.4132ZM110.696 39.0754C111.36 39.0754 111.872 38.8768 112.233 38.4795C112.602 38.0728 112.787 37.5384 112.787 36.8763V35.6277C112.787 34.9656 112.602 34.4359 112.233 34.0386C111.872 33.6319 111.36 33.4285 110.696 33.4285C110.44 33.4285 110.198 33.4616 109.971 33.5278C109.743 33.594 109.544 33.6934 109.373 33.8258C109.203 33.9487 109.065 34.1048 108.961 34.294C108.866 34.4737 108.819 34.6865 108.819 34.9325V37.5715C108.819 37.8174 108.866 38.035 108.961 38.2242C109.065 38.4039 109.203 38.5599 109.373 38.6924C109.544 38.8153 109.743 38.9099 109.971 38.9761C110.198 39.0423 110.44 39.0754 110.696 39.0754Z"
      fill={stroke}
    />
    <path
      d="M121.9 39.9126C121.454 39.9126 121.136 39.799 120.947 39.572C120.757 39.345 120.639 39.0613 120.591 38.7207H120.52C120.359 39.1464 120.098 39.4822 119.738 39.7281C119.387 39.9646 118.913 40.0828 118.315 40.0828C117.547 40.0828 116.935 39.8842 116.48 39.4869C116.025 39.0896 115.797 38.5457 115.797 37.8553C115.797 37.1742 116.044 36.6493 116.537 36.2804C117.04 35.9115 117.846 35.727 118.955 35.727H120.52V35.0034C120.52 34.4643 120.368 34.0623 120.065 33.7974C119.761 33.5231 119.33 33.3859 118.77 33.3859C118.277 33.3859 117.874 33.4853 117.561 33.6839C117.248 33.8731 116.997 34.1332 116.807 34.4643L116.039 33.8967C116.134 33.7075 116.262 33.5231 116.423 33.3434C116.585 33.1637 116.784 33.0076 117.021 32.8752C117.258 32.7333 117.528 32.6245 117.832 32.5488C118.135 32.4637 118.472 32.4211 118.842 32.4211C119.704 32.4211 120.387 32.6387 120.89 33.0738C121.402 33.5089 121.658 34.1143 121.658 34.8899V38.9194H122.682V39.9126H121.9ZM118.514 39.1322C118.808 39.1322 119.074 39.0991 119.311 39.0329C119.558 38.9572 119.771 38.8626 119.951 38.7491C120.131 38.6262 120.269 38.4843 120.364 38.3235C120.468 38.1532 120.52 37.9735 120.52 37.7843V36.5783H118.955C118.273 36.5783 117.775 36.6729 117.462 36.8621C117.149 37.0513 116.992 37.3256 116.992 37.685V37.983C116.992 38.3613 117.125 38.6498 117.391 38.8484C117.666 39.0376 118.04 39.1322 118.514 39.1322Z"
      fill={stroke}
    />
    <path
      d="M127.741 40.0828C126.973 40.0828 126.309 39.9551 125.749 39.6997C125.199 39.4349 124.73 39.0849 124.341 38.6498L125.109 37.983C125.46 38.3518 125.844 38.6356 126.261 38.8342C126.679 39.0329 127.181 39.1322 127.769 39.1322C128.338 39.1322 128.803 39.0329 129.163 38.8342C129.533 38.6262 129.718 38.3046 129.718 37.8694C129.718 37.6803 129.68 37.5242 129.604 37.4012C129.538 37.2688 129.443 37.1648 129.32 37.0891C129.196 37.0134 129.059 36.9567 128.907 36.9188C128.755 36.8715 128.594 36.8337 128.424 36.8053L127.271 36.6351C127.015 36.5972 126.736 36.5452 126.432 36.479C126.138 36.4128 125.868 36.3087 125.621 36.1669C125.375 36.0155 125.166 35.8169 124.995 35.5709C124.834 35.325 124.754 35.0034 124.754 34.6061C124.754 34.2372 124.825 33.9204 124.967 33.6555C125.119 33.3812 125.327 33.1542 125.593 32.9745C125.858 32.7853 126.171 32.6482 126.532 32.563C126.892 32.4684 127.286 32.4211 127.712 32.4211C128.376 32.4211 128.95 32.5252 129.433 32.7333C129.927 32.9414 130.349 33.2299 130.699 33.5988L129.96 34.294C129.874 34.1899 129.765 34.0859 129.633 33.9819C129.509 33.8683 129.353 33.769 129.163 33.6839C128.983 33.5893 128.77 33.5136 128.523 33.4569C128.277 33.4001 127.992 33.3718 127.67 33.3718C127.091 33.3718 126.645 33.4711 126.332 33.6697C126.029 33.8683 125.877 34.1568 125.877 34.5352C125.877 34.7244 125.91 34.8852 125.977 35.0176C126.053 35.1406 126.152 35.2399 126.276 35.3156C126.399 35.3912 126.536 35.4527 126.688 35.5C126.849 35.5378 127.011 35.5709 127.172 35.5993L128.324 35.7696C128.589 35.8074 128.869 35.8594 129.163 35.9256C129.457 35.9919 129.727 36.1006 129.974 36.252C130.221 36.3939 130.424 36.5878 130.586 36.8337C130.756 37.0796 130.842 37.4012 130.842 37.7985C130.842 38.5268 130.557 39.0896 129.988 39.4869C129.429 39.8842 128.68 40.0828 127.741 40.0828Z"
      fill={stroke}
    />
    <path
      d="M136.313 40.0828C135.782 40.0828 135.303 39.993 134.876 39.8132C134.459 39.6335 134.098 39.3781 133.795 39.0471C133.501 38.7066 133.273 38.3046 133.112 37.8411C132.951 37.3681 132.87 36.8432 132.87 36.2662C132.87 35.6797 132.951 35.15 133.112 34.6771C133.283 34.2041 133.515 33.8021 133.809 33.4711C134.103 33.1306 134.454 32.8704 134.862 32.6907C135.279 32.511 135.739 32.4211 136.242 32.4211C136.735 32.4211 137.18 32.511 137.579 32.6907C137.987 32.8704 138.333 33.1211 138.617 33.4427C138.902 33.7548 139.12 34.1285 139.272 34.5636C139.423 34.9987 139.499 35.4764 139.499 35.9966V36.5357H134.065V36.8763C134.065 37.1979 134.117 37.4958 134.222 37.7701C134.326 38.0444 134.473 38.2809 134.663 38.4795C134.862 38.6782 135.099 38.8342 135.374 38.9478C135.658 39.0518 135.971 39.1038 136.313 39.1038C136.806 39.1038 137.233 38.9903 137.593 38.7633C137.953 38.5363 138.228 38.2241 138.418 37.8269L139.257 38.3944C139.039 38.8863 138.674 39.293 138.162 39.6146C137.659 39.9267 137.043 40.0828 136.313 40.0828ZM136.242 33.3576C135.929 33.3576 135.639 33.4143 135.374 33.5278C135.108 33.6413 134.876 33.7974 134.677 33.996C134.487 34.1947 134.335 34.4312 134.222 34.7055C134.117 34.9703 134.065 35.2635 134.065 35.5851V35.6844H138.276V35.5284C138.276 35.2068 138.224 34.9136 138.119 34.6487C138.024 34.3839 137.887 34.1568 137.707 33.9677C137.536 33.769 137.323 33.6177 137.067 33.5136C136.82 33.4096 136.545 33.3576 136.242 33.3576Z"
      fill={stroke}
    />
    <path
      d="M142.765 29.4132H144.6L142.964 33.3434H142.011L142.765 29.4132ZM146.165 29.4132H148L146.364 33.3434H145.411L146.165 29.4132Z"
      fill={stroke}
    />
    <path
      d="M16.5036 47.2589C16.9019 47.2589 17.2006 47.3724 17.3997 47.5994C17.5989 47.817 17.6985 48.0913 17.6985 48.4224V49.3162C17.6985 49.5716 17.67 49.7986 17.6131 49.9973C17.5657 50.1864 17.5041 50.3567 17.4282 50.508C17.3523 50.6499 17.267 50.7823 17.1721 50.9053C17.0773 51.0188 16.9872 51.1276 16.9019 51.2316C16.7407 51.4208 16.6269 51.5864 16.5605 51.7282C16.5036 51.8607 16.4751 52.0025 16.4751 52.1539C16.4751 52.5133 16.6411 52.7782 16.973 52.9484C17.3049 53.1092 17.7933 53.1896 18.4382 53.1896H19.5477V54.0693H18.4382C17.7933 54.0693 17.3049 54.1544 16.973 54.3247C16.6411 54.4855 16.4751 54.7456 16.4751 55.105C16.4751 55.2564 16.5036 55.403 16.5605 55.5449C16.6269 55.6773 16.7407 55.8381 16.9019 56.0273C16.9872 56.1313 17.0773 56.2448 17.1721 56.3678C17.267 56.4813 17.3523 56.6137 17.4282 56.7651C17.5041 56.9164 17.5657 57.0867 17.6131 57.2759C17.67 57.465 17.6985 57.6873 17.6985 57.9427V58.8366C17.6985 59.1676 17.5989 59.4419 17.3997 59.6595C17.2006 59.8865 16.9019 60 16.5036 60H14V59.1203H16.7169V58.0278C16.7169 57.64 16.66 57.3421 16.5462 57.134C16.4324 56.9259 16.2997 56.7225 16.148 56.5239C15.9867 56.3158 15.835 56.103 15.6928 55.8854C15.5505 55.6584 15.4794 55.3935 15.4794 55.0909C15.4794 54.6936 15.6169 54.372 15.8919 54.1261C16.1764 53.8801 16.5605 53.7241 17.0441 53.6578V53.6011C16.5605 53.5349 16.1764 53.3788 15.8919 53.1329C15.6169 52.8869 15.4794 52.5653 15.4794 52.1681C15.4794 51.8654 15.5505 51.6053 15.6928 51.3877C15.835 51.1607 15.9867 50.9431 16.148 50.7351C16.2997 50.5364 16.4324 50.3331 16.5462 50.125C16.66 49.9169 16.7169 49.6189 16.7169 49.2311V48.1386H14V47.2589H16.5036Z"
      fill={stroke}
    />
  </Icon>
);

// export const Logo = ({
//   width = "162",
//   height = "70",
//   fill = "#1A365D",
//   stroke = "#1A365D",
// }) => (
//   <Icon
//     width={width + "px"}
//     height={height + "px"}
//     viewBox="0 0 162 70"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M17.0441 23.7411C16.6458 23.7411 16.3471 23.6276 16.148 23.4006C15.9488 23.183 15.8492 22.9087 15.8492 22.5776V21.6838C15.8492 21.4284 15.8729 21.2061 15.9204 21.0169C15.9773 20.8277 16.0436 20.6575 16.1195 20.5061C16.1954 20.3548 16.2807 20.2224 16.3755 20.1089C16.4704 19.9859 16.5605 19.8724 16.6458 19.7684C16.807 19.5792 16.9161 19.4184 16.973 19.286C17.0394 19.1441 17.0726 18.9975 17.0726 18.8461C17.0726 18.4867 16.9066 18.2266 16.5747 18.0658C16.2428 17.8955 15.7544 17.8104 15.1095 17.8104H14V16.9307H15.1095C15.7544 16.9307 16.2428 16.8503 16.5747 16.6895C16.9066 16.5192 17.0726 16.2544 17.0726 15.895C17.0726 15.7436 17.0394 15.6017 16.973 15.4693C16.9161 15.3274 16.807 15.1619 16.6458 14.9727C16.5605 14.8687 16.4704 14.7599 16.3755 14.6464C16.2807 14.5234 16.1954 14.391 16.1195 14.2491C16.0436 14.0978 15.9773 13.9275 15.9204 13.7383C15.8729 13.5397 15.8492 13.3127 15.8492 13.0573V12.1634C15.8492 11.8324 15.9488 11.5581 16.148 11.3405C16.3471 11.1135 16.6458 11 17.0441 11H19.5477V11.8797H16.8307V12.9722C16.8307 13.36 16.8876 13.6579 17.0014 13.866C17.1152 14.0741 17.248 14.2775 17.3997 14.4761C17.561 14.6842 17.7127 14.9018 17.8549 15.1288C17.9972 15.3463 18.0683 15.6065 18.0683 15.9091C18.0683 16.3064 17.9261 16.628 17.6416 16.8739C17.3665 17.1199 16.9872 17.2759 16.5036 17.3422V17.3989C16.9872 17.4651 17.3665 17.6212 17.6416 17.8671C17.9261 18.1131 18.0683 18.4347 18.0683 18.8319C18.0683 19.1346 17.9972 19.3995 17.8549 19.6265C17.7127 19.844 17.561 20.0569 17.3997 20.2649C17.248 20.4636 17.1152 20.6669 17.0014 20.875C16.8876 21.0831 16.8307 21.3811 16.8307 21.7689V22.8614H19.5477V23.7411H17.0441Z"
//       fill={fill}
//     />
//     <path
//       d="M32.3674 29.4132H33.3204L32.5665 33.3434H30.7315L32.3674 29.4132ZM35.7671 29.4132H36.7202L35.9663 33.3434H34.1313L35.7671 29.4132Z"
//       fill={fill}
//     />
//     <path
//       d="M39.5454 41.7854H43.1016V33.5562H39.4173V32.5914H44.2396V41.076C44.2396 41.5679 44.1068 41.9699 43.8413 42.282C43.5852 42.5941 43.1774 42.7502 42.6179 42.7502H39.5454V41.7854ZM43.6706 30.9739C43.3387 30.9739 43.1063 30.9077 42.9736 30.7753C42.8408 30.6334 42.7744 30.4584 42.7744 30.2503V30.0233C42.7744 29.8152 42.8408 29.645 42.9736 29.5125C43.1063 29.3707 43.3387 29.2997 43.6706 29.2997C44.0025 29.2997 44.2348 29.3707 44.3676 29.5125C44.5004 29.645 44.5667 29.8152 44.5667 30.0233V30.2503C44.5667 30.4584 44.5004 30.6334 44.3676 30.7753C44.2348 30.9077 44.0025 30.9739 43.6706 30.9739Z"
//       fill={fill}
//     />
//     <path
//       d="M50.9766 40.0828C50.2084 40.0828 49.5446 39.9551 48.9851 39.6997C48.4351 39.4349 47.9657 39.0849 47.5768 38.6498L48.345 37.983C48.6959 38.3518 49.0799 38.6356 49.4972 38.8342C49.9145 39.0329 50.4171 39.1322 51.005 39.1322C51.574 39.1322 52.0387 39.0329 52.3991 38.8342C52.7689 38.6262 52.9538 38.3046 52.9538 37.8694C52.9538 37.6803 52.9159 37.5242 52.84 37.4012C52.7737 37.2688 52.6788 37.1648 52.5555 37.0891C52.4323 37.0134 52.2948 36.9567 52.143 36.9188C51.9913 36.8715 51.8301 36.8337 51.6594 36.8053L50.5072 36.6351C50.2511 36.5972 49.9714 36.5452 49.6679 36.479C49.3739 36.4128 49.1036 36.3087 48.8571 36.1669C48.6105 36.0155 48.4019 35.8169 48.2312 35.5709C48.07 35.325 47.9894 35.0034 47.9894 34.6061C47.9894 34.2372 48.0605 33.9204 48.2027 33.6555C48.3545 33.3812 48.5631 33.1542 48.8286 32.9745C49.0942 32.7853 49.4071 32.6482 49.7675 32.563C50.1278 32.4684 50.5214 32.4211 50.9481 32.4211C51.612 32.4211 52.1857 32.5252 52.6693 32.7333C53.1625 32.9414 53.5845 33.2299 53.9354 33.5988L53.1957 34.294C53.1103 34.1899 53.0013 34.0859 52.8685 33.9819C52.7452 33.8683 52.5887 33.769 52.3991 33.6839C52.2189 33.5893 52.0055 33.5136 51.759 33.4569C51.5124 33.4001 51.2279 33.3718 50.9055 33.3718C50.327 33.3718 49.8813 33.4711 49.5683 33.6697C49.2649 33.8683 49.1131 34.1568 49.1131 34.5352C49.1131 34.7244 49.1463 34.8852 49.2127 35.0176C49.2886 35.1406 49.3881 35.2399 49.5114 35.3156C49.6347 35.3912 49.7722 35.4527 49.9239 35.5C50.0852 35.5378 50.2464 35.5709 50.4076 35.5993L51.5598 35.7696C51.8253 35.8074 52.1051 35.8594 52.3991 35.9256C52.693 35.9919 52.9633 36.1006 53.2099 36.252C53.4564 36.3939 53.6603 36.5878 53.8216 36.8337C53.9923 37.0796 54.0776 37.4012 54.0776 37.7985C54.0776 38.5268 53.7931 39.0896 53.2241 39.4869C52.6646 39.8842 51.9154 40.0828 50.9766 40.0828Z"
//       fill={fill}
//     />
//     <path
//       d="M59.4206 40.0828C58.9085 40.0828 58.4438 39.993 58.0265 39.8132C57.6188 39.6335 57.2679 39.3781 56.9739 39.0471C56.6894 38.7066 56.4713 38.3046 56.3196 37.8411C56.1678 37.3681 56.092 36.8384 56.092 36.252C56.092 35.675 56.1678 35.15 56.3196 34.6771C56.4713 34.2041 56.6894 33.8021 56.9739 33.4711C57.2679 33.1306 57.6188 32.8704 58.0265 32.6907C58.4438 32.511 58.9085 32.4211 59.4206 32.4211C59.9327 32.4211 60.3926 32.511 60.8004 32.6907C61.2177 32.8704 61.5685 33.1306 61.853 33.4711C62.147 33.8021 62.3699 34.2041 62.5216 34.6771C62.6733 35.15 62.7492 35.675 62.7492 36.252C62.7492 36.8384 62.6733 37.3681 62.5216 37.8411C62.3699 38.3046 62.147 38.7066 61.853 39.0471C61.5685 39.3781 61.2177 39.6335 60.8004 39.8132C60.3926 39.993 59.9327 40.0828 59.4206 40.0828ZM59.4206 39.1038C60.056 39.1038 60.5681 38.9146 60.9569 38.5363C61.3457 38.1579 61.5401 37.5715 61.5401 36.7769V35.727C61.5401 34.9325 61.3457 34.346 60.9569 33.9677C60.5681 33.5893 60.056 33.4001 59.4206 33.4001C58.7852 33.4001 58.2731 33.5893 57.8843 33.9677C57.4955 34.346 57.3011 34.9325 57.3011 35.727V36.7769C57.3011 37.5715 57.4955 38.1579 57.8843 38.5363C58.2731 38.9146 58.7852 39.1038 59.4206 39.1038Z"
//       fill={fill}
//     />
//     <path
//       d="M65.0765 39.9126V32.5914H66.2145V33.7832H66.2714C66.3473 33.6035 66.4421 33.4332 66.5559 33.2724C66.6697 33.1022 66.8072 32.9556 66.9684 32.8326C67.1391 32.7096 67.3383 32.6103 67.5659 32.5347C67.7935 32.459 68.059 32.4211 68.3625 32.4211C69.1306 32.4211 69.747 32.6671 70.2117 33.1589C70.6764 33.6413 70.9087 34.3271 70.9087 35.2162V39.9126H69.7707V35.4149C69.7707 34.7433 69.6237 34.2467 69.3297 33.9251C69.0358 33.594 68.6043 33.4285 68.0353 33.4285C67.8077 33.4285 67.5848 33.4569 67.3667 33.5136C67.1486 33.5704 66.9542 33.6555 66.7835 33.769C66.6128 33.8825 66.4753 34.0291 66.371 34.2089C66.2667 34.3886 66.2145 34.5967 66.2145 34.8332V39.9126H65.0765Z"
//       fill={fill}
//     />
//     <path
//       d="M74.5305 29.4132H76.3655L74.7296 33.3434H73.7766L74.5305 29.4132ZM77.9302 29.4132H79.7652L78.1294 33.3434H77.1763L77.9302 29.4132Z"
//       fill={fill}
//     />
//     <path
//       d="M85.0086 40.0402C84.6198 40.0402 84.3448 39.9598 84.1836 39.799C84.0319 39.6382 83.956 39.4349 83.956 39.189V38.9336C83.956 38.6876 84.0319 38.4843 84.1836 38.3235C84.3448 38.1627 84.6198 38.0823 85.0086 38.0823C85.3974 38.0823 85.6677 38.1627 85.8194 38.3235C85.9807 38.4843 86.0613 38.6876 86.0613 38.9336V39.189C86.0613 39.4349 85.9807 39.6382 85.8194 39.799C85.6677 39.9598 85.3974 40.0402 85.0086 40.0402ZM85.0086 34.4217C84.6198 34.4217 84.3448 34.3413 84.1836 34.1805C84.0319 34.0197 83.956 33.8163 83.956 33.5704V33.315C83.956 33.0691 84.0319 32.8657 84.1836 32.7049C84.3448 32.5441 84.6198 32.4637 85.0086 32.4637C85.3974 32.4637 85.6677 32.5441 85.8194 32.7049C85.9807 32.8657 86.0613 33.0691 86.0613 33.315V33.5704C86.0613 33.8163 85.9807 34.0197 85.8194 34.1805C85.6677 34.3413 85.3974 34.4217 85.0086 34.4217Z"
//       fill={fill}
//     />
//     <path
//       d="M100.602 29.4132H101.555L100.801 33.3434H98.9663L100.602 29.4132ZM104.002 29.4132H104.955L104.201 33.3434H102.366L104.002 29.4132Z"
//       fill={fill}
//     />
//     <path
//       d="M107.681 29.4132H108.819V33.7832H108.875C109.35 32.8752 110.104 32.4211 111.137 32.4211C112.038 32.4211 112.74 32.7569 113.242 33.4285C113.755 34.1001 114.011 35.0412 114.011 36.252C114.011 37.4627 113.755 38.4039 113.242 39.0754C112.74 39.747 112.038 40.0828 111.137 40.0828C110.104 40.0828 109.35 39.6288 108.875 38.7207H108.819V39.9126H107.681V29.4132ZM110.696 39.0754C111.36 39.0754 111.872 38.8768 112.233 38.4795C112.602 38.0728 112.787 37.5384 112.787 36.8763V35.6277C112.787 34.9656 112.602 34.4359 112.233 34.0386C111.872 33.6319 111.36 33.4285 110.696 33.4285C110.44 33.4285 110.198 33.4616 109.971 33.5278C109.743 33.594 109.544 33.6934 109.373 33.8258C109.203 33.9487 109.065 34.1048 108.961 34.294C108.866 34.4737 108.819 34.6865 108.819 34.9325V37.5715C108.819 37.8174 108.866 38.035 108.961 38.2242C109.065 38.4039 109.203 38.5599 109.373 38.6924C109.544 38.8153 109.743 38.9099 109.971 38.9761C110.198 39.0423 110.44 39.0754 110.696 39.0754Z"
//       fill={fill}
//     />
//     <path
//       d="M121.9 39.9126C121.454 39.9126 121.136 39.799 120.947 39.572C120.757 39.345 120.639 39.0613 120.591 38.7207H120.52C120.359 39.1464 120.098 39.4822 119.738 39.7281C119.387 39.9646 118.913 40.0828 118.315 40.0828C117.547 40.0828 116.935 39.8842 116.48 39.4869C116.025 39.0896 115.797 38.5457 115.797 37.8553C115.797 37.1742 116.044 36.6493 116.537 36.2804C117.04 35.9115 117.846 35.727 118.955 35.727H120.52V35.0034C120.52 34.4643 120.368 34.0623 120.065 33.7974C119.761 33.5231 119.33 33.3859 118.77 33.3859C118.277 33.3859 117.874 33.4853 117.561 33.6839C117.248 33.8731 116.997 34.1332 116.807 34.4643L116.039 33.8967C116.134 33.7075 116.262 33.5231 116.423 33.3434C116.585 33.1637 116.784 33.0076 117.021 32.8752C117.258 32.7333 117.528 32.6245 117.832 32.5488C118.135 32.4637 118.472 32.4211 118.842 32.4211C119.704 32.4211 120.387 32.6387 120.89 33.0738C121.402 33.5089 121.658 34.1143 121.658 34.8899V38.9194H122.682V39.9126H121.9ZM118.514 39.1322C118.808 39.1322 119.074 39.0991 119.311 39.0329C119.558 38.9572 119.771 38.8626 119.951 38.7491C120.131 38.6262 120.269 38.4843 120.364 38.3235C120.468 38.1532 120.52 37.9735 120.52 37.7843V36.5783H118.955C118.273 36.5783 117.775 36.6729 117.462 36.8621C117.149 37.0513 116.992 37.3256 116.992 37.685V37.983C116.992 38.3613 117.125 38.6498 117.391 38.8484C117.666 39.0376 118.04 39.1322 118.514 39.1322Z"
//       fill={fill}
//     />
//     <path
//       d="M127.741 40.0828C126.973 40.0828 126.309 39.9551 125.749 39.6997C125.199 39.4349 124.73 39.0849 124.341 38.6498L125.109 37.983C125.46 38.3518 125.844 38.6356 126.261 38.8342C126.679 39.0329 127.181 39.1322 127.769 39.1322C128.338 39.1322 128.803 39.0329 129.163 38.8342C129.533 38.6262 129.718 38.3046 129.718 37.8694C129.718 37.6803 129.68 37.5242 129.604 37.4012C129.538 37.2688 129.443 37.1648 129.32 37.0891C129.196 37.0134 129.059 36.9567 128.907 36.9188C128.755 36.8715 128.594 36.8337 128.424 36.8053L127.271 36.6351C127.015 36.5972 126.736 36.5452 126.432 36.479C126.138 36.4128 125.868 36.3087 125.621 36.1669C125.375 36.0155 125.166 35.8169 124.995 35.5709C124.834 35.325 124.754 35.0034 124.754 34.6061C124.754 34.2372 124.825 33.9204 124.967 33.6555C125.119 33.3812 125.327 33.1542 125.593 32.9745C125.858 32.7853 126.171 32.6482 126.532 32.563C126.892 32.4684 127.286 32.4211 127.712 32.4211C128.376 32.4211 128.95 32.5252 129.433 32.7333C129.927 32.9414 130.349 33.2299 130.699 33.5988L129.96 34.294C129.874 34.1899 129.765 34.0859 129.633 33.9819C129.509 33.8683 129.353 33.769 129.163 33.6839C128.983 33.5893 128.77 33.5136 128.523 33.4569C128.277 33.4001 127.992 33.3718 127.67 33.3718C127.091 33.3718 126.645 33.4711 126.332 33.6697C126.029 33.8683 125.877 34.1568 125.877 34.5352C125.877 34.7244 125.91 34.8852 125.977 35.0176C126.053 35.1406 126.152 35.2399 126.276 35.3156C126.399 35.3912 126.536 35.4527 126.688 35.5C126.849 35.5378 127.011 35.5709 127.172 35.5993L128.324 35.7696C128.589 35.8074 128.869 35.8594 129.163 35.9256C129.457 35.9919 129.727 36.1006 129.974 36.252C130.221 36.3939 130.424 36.5878 130.586 36.8337C130.756 37.0796 130.842 37.4012 130.842 37.7985C130.842 38.5268 130.557 39.0896 129.988 39.4869C129.429 39.8842 128.68 40.0828 127.741 40.0828Z"
//       fill={fill}
//     />
//     <path
//       d="M136.313 40.0828C135.782 40.0828 135.303 39.993 134.876 39.8132C134.459 39.6335 134.098 39.3781 133.795 39.0471C133.501 38.7066 133.273 38.3046 133.112 37.8411C132.951 37.3681 132.87 36.8432 132.87 36.2662C132.87 35.6797 132.951 35.15 133.112 34.6771C133.283 34.2041 133.515 33.8021 133.809 33.4711C134.103 33.1306 134.454 32.8704 134.862 32.6907C135.279 32.511 135.739 32.4211 136.242 32.4211C136.735 32.4211 137.18 32.511 137.579 32.6907C137.987 32.8704 138.333 33.1211 138.617 33.4427C138.902 33.7548 139.12 34.1285 139.272 34.5636C139.423 34.9987 139.499 35.4764 139.499 35.9966V36.5357H134.065V36.8763C134.065 37.1979 134.117 37.4958 134.222 37.7701C134.326 38.0444 134.473 38.2809 134.663 38.4795C134.862 38.6782 135.099 38.8342 135.374 38.9478C135.658 39.0518 135.971 39.1038 136.313 39.1038C136.806 39.1038 137.233 38.9903 137.593 38.7633C137.953 38.5363 138.228 38.2241 138.418 37.8269L139.257 38.3944C139.039 38.8863 138.674 39.293 138.162 39.6146C137.659 39.9267 137.043 40.0828 136.313 40.0828ZM136.242 33.3576C135.929 33.3576 135.639 33.4143 135.374 33.5278C135.108 33.6413 134.876 33.7974 134.677 33.996C134.487 34.1947 134.335 34.4312 134.222 34.7055C134.117 34.9703 134.065 35.2635 134.065 35.5851V35.6844H138.276V35.5284C138.276 35.2068 138.224 34.9136 138.119 34.6487C138.024 34.3839 137.887 34.1568 137.707 33.9677C137.536 33.769 137.323 33.6177 137.067 33.5136C136.82 33.4096 136.545 33.3576 136.242 33.3576Z"
//       fill={fill}
//     />
//     <path
//       d="M142.765 29.4132H144.6L142.964 33.3434H142.011L142.765 29.4132ZM146.165 29.4132H148L146.364 33.3434H145.411L146.165 29.4132Z"
//       fill={fill}
//     />
//     <path
//       d="M16.5036 47.2589C16.9019 47.2589 17.2006 47.3724 17.3997 47.5994C17.5989 47.817 17.6985 48.0913 17.6985 48.4224V49.3162C17.6985 49.5716 17.67 49.7986 17.6131 49.9973C17.5657 50.1864 17.5041 50.3567 17.4282 50.508C17.3523 50.6499 17.267 50.7823 17.1721 50.9053C17.0773 51.0188 16.9872 51.1276 16.9019 51.2316C16.7407 51.4208 16.6269 51.5864 16.5605 51.7282C16.5036 51.8607 16.4751 52.0025 16.4751 52.1539C16.4751 52.5133 16.6411 52.7782 16.973 52.9484C17.3049 53.1092 17.7933 53.1896 18.4382 53.1896H19.5477V54.0693H18.4382C17.7933 54.0693 17.3049 54.1544 16.973 54.3247C16.6411 54.4855 16.4751 54.7456 16.4751 55.105C16.4751 55.2564 16.5036 55.403 16.5605 55.5449C16.6269 55.6773 16.7407 55.8381 16.9019 56.0273C16.9872 56.1313 17.0773 56.2448 17.1721 56.3678C17.267 56.4813 17.3523 56.6137 17.4282 56.7651C17.5041 56.9164 17.5657 57.0867 17.6131 57.2759C17.67 57.465 17.6985 57.6873 17.6985 57.9427V58.8366C17.6985 59.1676 17.5989 59.4419 17.3997 59.6595C17.2006 59.8865 16.9019 60 16.5036 60H14V59.1203H16.7169V58.0278C16.7169 57.64 16.66 57.3421 16.5462 57.134C16.4324 56.9259 16.2997 56.7225 16.148 56.5239C15.9867 56.3158 15.835 56.103 15.6928 55.8854C15.5505 55.6584 15.4794 55.3935 15.4794 55.0909C15.4794 54.6936 15.6169 54.372 15.8919 54.1261C16.1764 53.8801 16.5605 53.7241 17.0441 53.6578V53.6011C16.5605 53.5349 16.1764 53.3788 15.8919 53.1329C15.6169 52.8869 15.4794 52.5653 15.4794 52.1681C15.4794 51.8654 15.5505 51.6053 15.6928 51.3877C15.835 51.1607 15.9867 50.9432 16.148 50.7351C16.2997 50.5364 16.4324 50.3331 16.5462 50.125C16.66 49.9169 16.7169 49.6189 16.7169 49.2311V48.1386H14V47.2589H16.5036Z"
//       fill={fill}
//     />
//     <rect
//       x="0.5"
//       y="0.5"
//       width={(width - 1).toString() + "px"}
//       height={(height - 1).toString() + "px"}
//       stroke={stroke}
//     />
//   </Icon>
// );

export const ErasePermIcon = ({
  width = "24",
  stroke = "#111111",
  fill = "#111111",
}) => (
  <Icon
    width={width}
    height={width}
    stroke={stroke}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 7.49951L4.39406 20.7422C4.42314 21.0186 4.55356 21.2746 4.76017 21.4606C4.96679 21.6466 5.23496 21.7495 5.51297 21.7495H18.487C18.765 21.7495 19.0332 21.6466 19.2398 21.4606C19.4464 21.2746 19.5769 21.0186 19.6059 20.7422L21 7.49951H3ZM14.625 17.6929L12 15.0679L9.375 17.6929L8.18156 16.4995L10.8066 13.8745L8.18156 11.2495L9.375 10.0561L12 12.6811L14.625 10.0561L15.8184 11.2495L13.1934 13.8745L15.8184 16.4995L14.625 17.6929Z"
      fill={fill}
    />
    <path
      d="M21.9375 2.25H2.0625C1.75184 2.25 1.5 2.50184 1.5 2.8125V5.4375C1.5 5.74816 1.75184 6 2.0625 6H21.9375C22.2481 6 22.5 5.74816 22.5 5.4375V2.8125C22.5 2.50184 22.2481 2.25 21.9375 2.25Z"
      fill={fill}
    />
  </Icon>
);

export const ClipboardIcon = ({
  width = "17",
  height = "19",
  stroke = "#111111",
}) => (
  <Icon
    width={width}
    height={height}
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://wwww.w3.org/2000/svg"
  >
    <path d="M10.39 6.44995H3.91003V6.98995H10.39V6.44995Z" fill="black" />
    <path d="M10.39 9.1499H3.91003V9.6899H10.39V9.1499Z" fill="black" />
    <path d="M10.39 11.8501H3.91003V12.3901H10.39V11.8501Z" fill="black" />
    <path d="M10.39 14.55H3.91003V15.09H10.39V14.55Z" fill="black" />
    <path
      d="M3.63994 3.21001H0.939941V18.33H13.3599V15.63H16.0599V0.51001H3.63994V3.21001ZM12.8199 17.79H1.47994V3.75001H12.8199V17.79ZM15.5199 1.05001V15.09H13.3599V3.21001H4.17994V1.05001H15.5199Z"
      fill="black"
    />
  </Icon>
);
export const SaveIcon = ({ width = "6", stroke = "#ffffff" }) => (
  <Icon
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8568 2.68983C17.7174 2.55055 17.5521 2.44007 17.3701 2.36471C17.188 2.28935 16.993 2.25058 16.796 2.25061H4.41723C4.1327 2.25055 3.85094 2.30655 3.58805 2.4154C3.32517 2.52426 3.0863 2.68385 2.88511 2.88504C2.68391 3.08624 2.52432 3.3251 2.41547 3.58799C2.30661 3.85088 2.25061 4.13264 2.25067 4.41717V19.584C2.25061 19.8686 2.30661 20.1503 2.41547 20.4132C2.52432 20.6761 2.68391 20.915 2.88511 21.1162C3.0863 21.3174 3.32517 21.4769 3.58805 21.5858C3.85094 21.6947 4.1327 21.7507 4.41723 21.7506H19.5841C20.1582 21.7489 20.7082 21.5201 21.1142 21.1141C21.5201 20.7082 21.7489 20.1581 21.7507 19.584V7.2053C21.7507 7.0083 21.7119 6.81323 21.6366 6.63122C21.5612 6.44921 21.4507 6.28383 21.3114 6.14452L17.8568 2.68983ZM12.0007 19.5006C11.4073 19.5006 10.8273 19.3247 10.334 18.995C9.84061 18.6654 9.45609 18.1968 9.22903 17.6487C9.00197 17.1005 8.94256 16.4973 9.05831 15.9153C9.17407 15.3334 9.45979 14.7988 9.87935 14.3793C10.2989 13.9597 10.8335 13.674 11.4154 13.5582C11.9973 13.4425 12.6005 13.5019 13.1487 13.729C13.6969 13.956 14.1654 14.3405 14.4951 14.8339C14.8247 15.3272 15.0007 15.9073 15.0007 16.5006C15.0012 16.8947 14.9239 17.285 14.7733 17.6492C14.6227 18.0134 14.4018 18.3444 14.1231 18.623C13.8444 18.9017 13.5135 19.1227 13.1493 19.2732C12.7851 19.4238 12.3948 19.5011 12.0007 19.5006V19.5006ZM14.2507 9.00061H5.25067C5.05176 9.00061 4.86099 8.92159 4.72034 8.78094C4.57969 8.64029 4.50067 8.44952 4.50067 8.25061V5.25061C4.50067 5.0517 4.57969 4.86093 4.72034 4.72028C4.86099 4.57963 5.05176 4.50061 5.25067 4.50061H14.2507C14.4496 4.50061 14.6403 4.57963 14.781 4.72028C14.9216 4.86093 15.0007 5.0517 15.0007 5.25061V8.25061C15.0007 8.44952 14.9216 8.64029 14.781 8.78094C14.6403 8.92159 14.4496 9.00061 14.2507 9.00061Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
export const LoginIcon = ({ width = "6", stroke = "#ffffff" }) => (
  <Icon
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00012 8.24951V6.37451C9.00012 5.87723 9.19767 5.40032 9.5493 5.04869C9.90093 4.69706 10.3778 4.49951 10.8751 4.49951H18.3751C18.8724 4.49951 19.3493 4.69706 19.7009 5.04869C20.0526 5.40032 20.2501 5.87723 20.2501 6.37451V17.6245C20.2501 18.1218 20.0526 18.5987 19.7009 18.9503C19.3493 19.302 18.8724 19.4995 18.3751 19.4995H11.2501C10.2147 19.4995 9.00012 18.66 9.00012 17.6245V15.7495"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5001 15.7498L17.2501 11.9998L13.5001 8.24976"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.74994 12.001H16.4999"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export const LogoutIcon = ({ width = "6", stroke = "#ffffff" }) => (
  <Icon
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 15.749V17.624C14.25 18.1213 14.0525 18.5982 13.7008 18.9498C13.3492 19.3015 12.8723 19.499 12.375 19.499H4.875C4.37772 19.499 3.90081 19.3015 3.54917 18.9498C3.19754 18.5982 3 18.1213 3 17.624V6.37402C3 5.87674 3.19754 5.39983 3.54917 5.0482C3.90081 4.69657 4.37772 4.49902 4.875 4.49902H12C13.0355 4.49902 14.25 5.33855 14.25 6.37402V8.24902"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2499 15.7495L20.9999 11.9995L17.2499 8.24951"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12.0005H20.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
