import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import ReactDOM from "react-dom";
import styled from "styled-components";
import { AuthContext } from "./FirebaseAuthContext";
import { subsaccountPost, subsbeginPost } from "./Api";
import { loadStripe } from "@stripe/stripe-js";
import { SaveIcon } from "./SvgIcons";
import { Input, FormControl, Link } from "@chakra-ui/react";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useToast, Button } from "@chakra-ui/react";
import { config } from "./Config";

const stripePromise = loadStripe(
  "pk_live_51IXpWRGZtPkmUqABk7iqKQgRJWnmR1eOSOtFO9QmHeRA6j2jIJF6ijNdIulWDEkjh7LWvsOZJPGhNNihClhVr96X00BsSIitRn"
);

const Wrapper = styled.div`
  margin: 31px 93px;
  color: #111111;
  display: flex;
  span {
    margin: 0px 5px;
    font-size: 12px;
  }
  .fldGrp {
    // flex-flow: row-wrap;
    margin-bottom: 30px;
    span {
      font-size: 24px;
    }
    .cardElement {
      width: 400px;
    }
    .label {
      color: #666666;
      font-size: 12px;
      span {
        margin: 0px 0px;
        color: #111111;
        font-size: 12px;
      }
    }
  }
  span {
    margin: 3px 5px;
    font-size: 12px;
  }
`;

const businessPlanText = `€ 9,00 per month per GB. Charged at € 0.0087 per MB after
the first GB usage. Usage will be calculated based on maximum
level reached (high watermark) in a given billing period.`;

export default function PaymentWrapper(props) {
  return (
    <Wrapper>
      <div className="paymentInput">
        <Elements stripe={stripePromise}>
          <Payment {...props} />
        </Elements>
      </div>
    </Wrapper>
  );
}

function Payment(props) {
  const stripe = useStripe();
  const elements = useElements();
  const authValue = useContext(AuthContext);
  const [emailAddress, setEmailAddress] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const [subsActive, setSubsActive] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const toast = useToast();
  let { priceId } = useParams();

  useEffect(() => {
    if (authValue.user != null) {
      setEmailAddress(authValue.user.email);
    }
  }, [authValue]);

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const onCardChange = (event) => {
    if (event.complete) {
      setCardComplete(true);
    } else {
      setCardComplete(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (authValue.userDataPresent) {
        if (authValue.user != null) {
          const cardEl = elements.getElement(CardElement);
          cardEl.update({ disabled: true });
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardEl,
          });
          setIsSubscribing(true);
          if (error) {
            toast({
              position: "top",
              title: "Something went wrong",
              description: "Please try again",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            return;
          }
          const stripeCustomer = await subsaccountPost(authValue.user.uid, {
            email: emailAddress,
          });
          const stripeSubscription = await subsbeginPost(authValue.user.uid, {
            customerId: stripeCustomer.customer.id,
            paymentMethodId: paymentMethod.id,
            priceId: priceId,
            planType: Object.keys(config.priceIds).find(
              (key) => config.priceIds[key] === priceId
            ),
          });
          setIsSubscribing(false);
          if (stripeSubscription.subscription.status === "active") {
            setSubsActive(true);
            toast({
              position: "top",
              title: "Subscribed",
              description: "Welcome to business plan.",
              status: "success",
              duration: 4000,
              isClosable: true,
            });
            setIsSubscribing(false);
          } else {
            toast({
              position: "top",
              title: "Something went wrong",
              description: "We will get in touch with you.",
              status: "error",
              duration: 4000,
              isClosable: true,
            });
            setIsSubscribing(false);
          }
        }
      }
    } catch (err) {
      //
    }
  };

  return (
    <div className="fldGrp">
      <span>Business Plan</span>
      <div className="label">{businessPlanText}</div>
      {!subsActive ? (
        <div className="cardElement">
          <FormControl>
            <Input
              required
              defaultValue={emailAddress}
              marginTop="20px"
              marginBottom="20px"
              variant="unstyled"
              placeHolder="Email"
              type="email"
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
            <CardElement
              onChange={onCardChange}
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />

            <Button
              isLoading={isSubscribing}
              disabled={
                !stripe || !validateEmail(emailAddress) || !cardComplete
              }
              marginTop="25px"
              onClick={handleSubmit}
              marginBottom="60px"
              leftIcon={<SaveIcon />}
              colorScheme="blue"
              bgColor="blue.900"
              color="white"
              variant="solid"
            >
              Setup Subscription
            </Button>
          </FormControl>
        </div>
      ) : (
        <>
          <div className="label" style={{ marginTop: "40px" }}>
            <span>
              Welcome to the business plan. We will send a notification when an
              invoice payment is processed. We are only a click away when you
              need us :-)
            </span>
          </div>
          <Link href="/">
            <Button
              marginTop="25px"
              leftIcon={<SaveIcon />}
              colorScheme="blue"
              bgColor="blue.900"
              color="white"
              variant="solid"
            >
              Return to home page
            </Button>
          </Link>
        </>
      )}
    </div>
  );
}
