export const termsMdText = `
# JSON Base Terms of Use
These are the terms and conditions of use for the platform at the web domain jsonbase.io (“our website”). Please read them carefully because they form a legal agreement between you and us in relation to access and use of our website.

&nbsp;

# 1. Who we are

&nbsp;

We are JSON Base, a trading name of Maekwae OÜ, a company registered in Estonia under company registration number 14385535 with our registered office address at Sepapaja tn 6 Tallinn Harjumaa 15551 ("we", or "us", or "our"). 

&nbsp;

# 2. What are these terms about?

&nbsp;

These terms apply to your use of our website.  By accessing our website you agree to these terms. If you do not agree to them you should not access our website. The most current version of these terms is dated above but we may change them from time to time without giving you notice, so please try to read these terms every time you visit our website. By accessing our website after changes are made to these terms you agree to the revised terms.

&nbsp;

# 3. Using our website

&nbsp;

We are pleased to provide our website to you for use but you must use our website in an acceptable way. It is impossible to provide an exhaustive list of exactly what constitutes acceptable and unacceptable use of our website. In general, we will not tolerate any use of our website that damages or is likely to damage our reputation, the availability or integrity of our website or which causes us or threatens to cause us to incur any legal, tax or regulatory liability. 

&nbsp;

We want to make our website a safe and rewarding experience, so we have set out more detail on acceptable and unacceptable use below.

&nbsp;

As you might expect, you may only use our website for lawful purposes. For example, you must not use our website:

&nbsp;

* in any way that breaches any applicable local, national or international law or regulation;
* in any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;
* to send any unsolicited or unauthorised advertising or promotional material or any other form of unsolicited communication (spam); or
* to transmit any data, send or upload any material that contains any harmful programs, such as viruses, Trojan horses, worms, or time-bombs.

&nbsp;

You also agree not to :

&nbsp;

* reproduce, duplicate, copy or re-sell any part of our website; or
* access without authority, interfere with, damage or disrupt our website, or any related equipment, network or software.

&nbsp;

Interactive services

&nbsp;

We may from time to time provide interactive services on our website.  If we do provide any interactive service, we will provide clear information to you about the kind of service offered.

&nbsp;

We will do our best to assess any possible risks for users from third parties when they use any interactive service provided on our website, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any interactive service we provide on our website.

&nbsp;

If we do decide to monitor or moderate an interactive service, we will normally provide you with a means of contacting the moderator, should you need to do so.

&nbsp;

Our interactive services are not intended for use by children. Parents and guardians should not permit their children to use our interactive services, as these are only intended for use by businesses.

&nbsp;

If you use our interactive services, you will also need to understand and comply with our content standards.

&nbsp;

Content standards

&nbsp;

If you contribute material to our website, you agree that any material you contribute will: 

&nbsp;

* comply with all applicable laws and regulations;
* be factually accurate and/or will represent your genuinely held belief or opinion;
* not be defamatory, offensive, hateful or inflammatory; and
* not infringe confidentiality, or any copyright or intellectual property rights of another person.

&nbsp;

You also agree that we can use any content you contribute to our website whenever and wherever we think appropriate.

&nbsp;

Our suspension and termination rights

&nbsp;

The terms set out in this section 3 are intended to protect us, our website and its users, so a failure to comply with this section constitutes a serious breach of these terms. If we believe you have breached this section 3, we may do any or all of the following (as well as take any other action we feel is appropriate):

&nbsp;

* issue a warning to you and ask you to take certain steps to rectify your behaviour.
* suspend your right to access our website, either temporarily or permanently.
* take legal action against you, including to recover any loss we have suffered due to your actions.
* disclose information to law enforcement authorities.

&nbsp;

# 4. Buying products/services through our website

&nbsp;

Our website allows you to buy software and/or other products and services from us. 

&nbsp;

# 5. Other things you need to know about our website

&nbsp;

We take steps to ensure that all information we provide on our website about our products and services is correct and complete at the time of the last update to the relevant page, but we do not promise that it will be correct and complete.

&nbsp;

We aim to update our website regularly, and may change the content at any time. If the need arises, we may suspend access to our website, or close it indefinitely.

&nbsp;

Use of our website is free and we take steps to ensure it is available to all permitted users. From time to time we may restrict access to some or all parts of our website. We will try to ensure that our website is always available to you but we have to be realistic and so we do not promise that our website will be continuously available. We also do not promise that your use of our website will be uninterrupted or error-free, or that our website and server will be free from attack (e.g. from viruses). We recommend that you use your own appropriate virus checking software.

&nbsp;

Separate, limited promises which apply to products and services sold through our website may be set out in specific Terms and Conditions if applicable.

&nbsp;

# 6. Links to/from our website

&nbsp;

You are allowed to establish links to our website as long as you obtain our written consent in advance (see above for how to contact us). We reserve the right to withdraw our consent at any time.

&nbsp;

Links must only be made to the home page of our website and you are not allowed (and you shall not assist others) to set up links from your own website to our website by deep linking, framing or otherwise, without our prior written consent. We may withhold our consent if we wish. If we do so, we will try to explain our reasons to you.

&nbsp;

Our website includes links that allow you to leave our website and visit third-party websites. We have no control over those websites, so we are not responsible for the content, use by you or availability of those third-party websites, for any products or services you buy through those websites or for the treatment of any personal information you provide to the Third party.

&nbsp;

# 7. Community areas of our website (registered users only)

&nbsp;

Certain areas of our website may be reserved for registered users. These include, any community areas for Developers, Business Partners and members. Additional terms may apply to those areas, such as specific acceptable use policies or specific terms for buying licences for any specialised products or services which are available only to registered users.

&nbsp;

If you are a registered user, please make sure any information you provide to us is up to date and accurate, and let us know of any changes as soon as you can.

&nbsp;

You will be responsible for keeping your user name and password confidential and secure and for all use made of your account, whether or not it is used by you or with your authority.  Please notify us immediately if you become aware of any unauthorised use or other security breach and always ensure you close your browser at the end of each session.

&nbsp;

You may not transfer any rights we grant you as a registered user to anyone else.

&nbsp;

# 8. Intellectual property rights

&nbsp;

The design and content of our website, and the material published on it, is protected by copyright and is owned by us (©2000-2011) and our licensors. All rights are reserved. We or our licensors retain the exclusive right to authorise or prohibit the direct or indirect, temporary or permanent reproduction of our website by any means and in any form, in whole or in part, and to make our website available to the public, and to distribute any copyright-protected material in our website (including without limit, any online software or courses). You agree not to copy, adapt, alter or create any derivative work from any material on our website, or to restrict or inhibit the use or enjoyment of our website by anyone else.

&nbsp;

We own various trade marks and registered trade marks (the "marks"), a list of which can be obtained from us. You may not use those marks, and/or any third-party trade marks that appear on our website, other than as permitted by express written licence from the licensor or by law. In particular, but without limit, you may not use the marks as meta-tags and you may not sponsor them in search engines. All goodwill in your legitimate use of the marks shall accrue to Maekwae OÜ. Please notify us immediately if you become aware of any infringement of the marks.

&nbsp;

We or our licensors own all other intellectual property rights in our website.

&nbsp;

We may grant you express permissions relating to use of material on our website from time to time but except where we do so, nothing in these terms or on our website confers any licence or right to use any material on our website without the prior written approval of the owner, whether that is us or our licensors.

&nbsp;

# 9. What happens if things don't go as planned?

&nbsp;

We will always try to ensure our website and the material on it meets the standards we have promised and that you can use our website as intended in relation to your business.  However, because of the nature of our website and the wide range of information, products and services we provide, sometimes things may go wrong. If we are at fault, we will always try to resolve any problems we have caused, but unfortunately we will not compensate you for any loss or damage you might suffer (of any nature and however caused) in connection with:

&nbsp;

* our website; or
* your use, inability to use, or results of the use of our website, any websites linked to it and any materials posted on it; or
* the use of (or any inability to use) any interactive service on our website, whether the service is moderated or not.

&nbsp;

Of course, this does not affect our liability for death or personal injury arising from our negligence, or our liability for fraud (including fraudulent misrepresentation) in the unlikely event that this should happen, or any other liability which we are not allowed to limit or exclude by law.

&nbsp;

# 10. Cookies

&nbsp;

We use cookies on our website (which are small text files that allow our website to "remember" who you are). Further information about cookies, how we use them to store and access information about you and how you can choose to reject cookies can be found in our Privacy Policy.

&nbsp;

# 11. Your personal information

&nbsp;

Our website includes areas where you are requested to provide information about yourself.  Any personal data you provide to us through our website shall be subject to the terms of our Privacy Policy which also forms part of these terms.

&nbsp;

# 12. General

&nbsp;

If a court or other competent authority rules that any of these terms are void or unenforceable in whole or part, the other terms and the remainder of the void or unenforceable term will still be valid.

&nbsp;

Nothing in these terms excludes or limits our liability for death or personal injury due to our negligence, or our liability for fraudulent misrepresentation.

&nbsp;

If you are a consumer, your statutory legal rights are not affected by these terms.

&nbsp;

If we fail to exercise or delay in exercising a right or remedy provided by these terms or by law, that does not mean we give up those or any other rights or remedies. We reserve the right to exercise those rights or remedies in the future. 

&nbsp;

These terms and the use of our website and its content shall be governed by and construed in all respects in accordance with English law. We and you agree that the English courts shall have exclusive jurisdiction to settle any claims or proceedings relating to these terms or their subject matter.

&nbsp;

# 13. Need to get in touch?

&nbsp;

If you would like to contact us about our website or any of the material on it, please email us on support@jsonbase.io

&nbsp;

Thank you for visiting our website.
`;
