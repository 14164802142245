// Import FirebaseAuth and firebase.
import React from "react";
import { Button, Link } from "@chakra-ui/react";
import styled from "styled-components";
import { SaveIcon } from "./SvgIcons";
import { config } from "./Config";

const Wrapper = styled.div`
  margin: 31px 93px;
  span {
    margin: 0px 5px;
    font-size: 12px;
  }
  .cardgrp {
    margin-top: 120px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .card {
      position: relative;
      span {
        margin-top: 3px;
        font-size: 12px;
        color: #666666;
      }
      display: flex;
      flex-direction: column;
      margin: 10px 20px;
      background-color: #e2e8f0;
      //   justify-content: center;
      width: 240px;
      height: 360px;
      border-width: 1px;
      .heading {
        margin-top: 20px;
        height: 50px;
        span {
          color: #000000;
          font-size: 24px;
        }
      }
      .pricebar {
        position: absolute;
        height: 80px;
        top: 180px;
        color: #ffffff;
        margin: 10px 0px 30px 0px;
        background-color: #276749;
        width: 100%;
        p {
          font-size: 16px;
          line-height: 80px;
          //   transform: translateY(50%);
        }
        .main {
          margin: 0px 1px;
          color: #ffffff;
          font-size: 24px;
        }
        .sub {
          color: #ffffff;
          font-size: 12px;
        }
      }
      .actionbtn {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export default function Pricing(props) {
  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     setIsSignedIn(!!user);
  //   });
  // });

  return (
    <Wrapper>
      <div className="cardgrp">
        <div className="card">
          <div className="heading">
            <span>Business</span>
          </div>
          <span>No Usage Limit (Metered Billing)</span>
          <span>Dedicated Support</span>
          <span>Discounted Data Modeling</span>
          <div className="pricebar">
            <div className="main"> € 9,00 per month </div>
            <div className="sub"> per GB </div>
            <div className="sub"> minimum 1 GB </div>
          </div>
          <div className="actionbtn">
            <Link href={"/payment/" + config.priceIds.Business}>
              <Button
                // width="fit-content"
                // marginTop="25px"
                leftIcon={<SaveIcon />}
                colorScheme="blue"
                bgColor="blue.900"
                color="white"
                variant="solid"
              >
                Continue
              </Button>
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="heading">
            <span>Enterprise</span>
          </div>
          <span>Business Benefits Included</span>
          <span>Multiple Accounts</span>
          <span>Staging Environment</span>
          <span>Single Sign On</span>
          <span>Custom Domain / Whitelabel Options</span>
          <div className="pricebar" style={{ backgroundColor: "#A0AEC0" }}>
            <p>Competitive Pricing Available</p>
          </div>
          <div className="actionbtn">
            <Link href="javascript:Tawk_API.maximize();">
              <Button
                // width="fit-content"
                // marginTop="25px"
                leftIcon={<SaveIcon />}
                colorScheme="blue"
                bgColor="blue.900"
                color="white"
                variant="solid"
              >
                Chat With Us
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
