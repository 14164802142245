// Import FirebaseAuth and firebase.
import React, { useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";

export default function Auth(props) {
  // const history = useHistory();
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: "/",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult) => {
        // console.log("here " + authResult.user.uid);
        setIsSignedIn(true);
        return true;
      },
    },
  };

  const [isSignedIn, setIsSignedIn] = useState(false);

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     setIsSignedIn(!!user);
  //   });
  // });

  return (
    <div>
      {!isSignedIn && (
        <>
          <div
            style={{ textAlign: "center", marginTop: "31px", fontSize: "24px" }}
          >
            <span>Sign In</span>
          </div>

          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </>
      )}
    </div>
  );
}
