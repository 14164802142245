import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
// import JSONInput from "react-json-editor-ajrm";
import { Input, ButtonGroup, Button, useToast, Link } from "@chakra-ui/react";
import { ClipboardIcon, ErasePermIcon, LogoutIcon, SaveIcon } from "./SvgIcons";
// import { usedBytesGet, docsGet, docsPut } from "./Api";
import { config } from "./Config";
import firebase from "firebase";
import { makeid, copyToClipboard } from "./Utils";
import {
  accountPut,
  accountDelete,
  subsendPost,
  usedBytesGet,
  subsundocancelPost,
} from "./Api";
import { AuthContext } from "./FirebaseAuthContext";

const Wrapper = styled.div`
  margin: 31px 93px;
  color: #111111;
  .fldGrp {
    margin-bottom: 30px;
    // display: flex;
    // flex-flow: row wrap;
    span {
      font-size: 24px;
    }
    .label {
      color: #666666;
      font-size: 12px;
      span {
        margin: 0px 0px;
        color: #111111;
        font-size: 12px;
      }
    }
  }
  span {
    margin: 3px 5px;
    font-size: 12px;
  }
`;

const businessPlanText = ` - € 9,00 per month per GB. Charged at € 0.0087 per MB after
the first GB usage. Usage will be calculated based on maximum
level reached (high watermark) in a given billing period.`;

export default function UserAccount(props) {
  const [isReactivating, setIsReactivating] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  // const [isReactivating, setIsReactivating] = useState(false);
  const [usedBytes, setUsedBytes] = useState(0.0);
  // const [placeholder, setPlaceholder] = useState([]);
  // const [textContent, setTextContent] = useState("");
  const authValue = useContext(AuthContext);

  const [accountId, setAccountId] = useState(
    window.localStorage.getItem("clientid")
  );
  const [accountSecret, setAccountSecret] = useState(
    window.localStorage.getItem("secret")
  );

  const [subsCancelled, setSubsCancelled] = useState(false);

  // const toast = useToast();
  const toast = useToast();
  useEffect(() => {
    if (
      authValue.user != null &&
      authValue.planType === "Business" &&
      authValue.subsCancelledAt
    ) {
      setSubsCancelled(true);
    }

    usedBytesGet().then((sizeobj) => {
      setUsedBytes(sizeobj.size);
    });
  }, [authValue]);

  const onCopyText = () => {
    // setIsCopied(true);
    // setTimeout(() => {
    //   setIsCopied(false);
    // }, 1000);
    copyToClipboard(accountSecret);

    toast({
      position: "top",
      title: "Copied",
      description: "Copied secret to clipboard.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const signout = async () => {
    localStorage.clear();
    await firebase.auth().signOut();
    window.location.replace("/");
  };

  const inputChanged = (e) => {
    setAccountId(e.target.value);
  };

  const saveAccountId = async () => {
    await accountPut(firebase.auth().currentUser.uid, accountId, accountSecret);
    window.localStorage.setItem("clientid", accountId);
    toast({
      position: "top",
      title: "Saved",
      description: "Please update your code with the new API endpoint URL.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    // await firebase.auth().signOut();
    // window.location.replace("/");
  };

  const erasePermanently = async () => {
    await accountDelete(firebase.auth().currentUser.uid);
    await firebase.auth().signOut();
    localStorage.clear();
    window.localStorage.setItem("justErased", true);
    // await firebase.auth().signOut();
    window.location.replace("/");
  };

  const refreshSecret = async () => {
    const newsec = makeid(32);
    await accountPut(
      firebase.auth().currentUser.uid,
      window.localStorage.getItem("clientid"),
      newsec
    );
    window.localStorage.setItem("secret", newsec);
    setAccountSecret(newsec);
    toast({
      position: "top",
      title: "Secret Refreshed",
      description: "Please update your code to use the new secret.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const cancelSubscription = async () => {
    setIsCancelling(true);
    const res = await subsendPost(authValue.accountUid);
    setIsCancelling(false);
    if (res.success) {
      setSubsCancelled(true);
      toast({
        position: "top",
        title: "Subscription Cancelled",
        description:
          "Your account will revert to free plan and will no longer be charged after this period ends.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const undocancelSubscription = async () => {
    setIsReactivating(true);
    const res = await subsundocancelPost(authValue.accountUid);
    setIsReactivating(false);
    if (res.success) {
      setSubsCancelled(false);
      toast({
        position: "top",
        title: "Subscription Reactivated",
        description: "Your account is now active.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Wrapper>
        <div className="fldGrp">
          <span>Subscription </span>
          <div className="label">
            <span>
              {authValue.planType ? authValue.planType + " Plan" : "Free Plan"}
            </span>
            {authValue.planType === "Business" && subsCancelled && (
              <>
                <span>
                  {" (Cancels " +
                    (authValue.subsLastDate
                      ? authValue.subsLastDate.toLocaleDateString()
                      : "") +
                    ")"}
                </span>
                <span>{businessPlanText}</span>
              </>
            )}
            {authValue.planType === "Business" && !subsCancelled && (
              <>
                <span>{businessPlanText}</span>
              </>
            )}
          </div>
          {authValue.planType === "Business" && subsCancelled && (
            <Button
              isLoading={isReactivating}
              marginTop="25px"
              onClick={undocancelSubscription}
              marginBottom="60px"
              leftIcon={<SaveIcon />}
              colorScheme="blue"
              bgColor="blue.900"
              color="white"
              variant="solid"
            >
              Reactivate Subscription
            </Button>
          )}
          {authValue.planType === "Business" &&
            !subsCancelled &&
            (usedBytes <= 100.0 ? (
              <Button
                isLoading={isCancelling}
                marginTop="25px"
                onClick={cancelSubscription}
                marginBottom="60px"
                leftIcon={<SaveIcon />}
                colorScheme="blue"
                bgColor="blue.900"
                color="white"
                variant="solid"
              >
                Cancel Subscription
              </Button>
            ) : (
              <Link href="javascript:Tawk_API.maximize();">
                <Button
                  // width="fit-content"
                  // marginTop="25px"
                  leftIcon={<SaveIcon />}
                  colorScheme="blue"
                  bgColor="blue.900"
                  color="white"
                  variant="solid"
                >
                  Chat with us to make changes
                </Button>
              </Link>
            ))}
        </div>
        <div className="fldGrp">
          <span>Account ID</span>
          <Input
            onChange={inputChanged}
            maxWidth="320px"
            display="block"
            value={accountId}
          />

          <div className="label">
            {config.jbApiUrl + "/"}
            <span>{accountId + "/"}</span>
          </div>
        </div>
        <Button
          onClick={saveAccountId}
          marginBottom="60px"
          leftIcon={<SaveIcon />}
          colorScheme="blue"
          bgColor="blue.900"
          color="white"
          variant="solid"
        >
          Modify Account ID
        </Button>

        <div className="fldGrp">
          <span>Secret</span>

          <div>
            {accountSecret}
            <span style={{ marginLeft: "10px" }}>
              <Link onClick={onCopyText}>
                <ClipboardIcon width="17px" height="19px" />
              </Link>
            </span>
            <Button
              onClick={refreshSecret}
              // leftIcon={<SaveIcon />}
              marginLeft="30px"
              size="sm"
              colorScheme="blue.900"
              // bgColor="blue.900"
              // color="blue.900"
              variant="outline"
            >
              Refresh
            </Button>
          </div>
        </div>

        <div className="btnGroup">
          <ButtonGroup marginTop="40px">
            <Button
              onClick={() => signout()}
              leftIcon={<LogoutIcon />}
              colorScheme="blue"
              bgColor="blue.900"
              color="white"
              variant="solid"
            >
              Sign Out
            </Button>
            <Button
              onClick={erasePermanently}
              leftIcon={
                <ErasePermIcon width="24px" stroke="red" fill="white" />
              }
              colorScheme="red"
              bgColor="red.50"
              color="red"
              variant="outline"
            >
              Erase Permanently
            </Button>
          </ButtonGroup>
        </div>
      </Wrapper>
    </>
  );
}
